<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row :gutter="30">
      
              <el-col :span="12">
          <div class="mt-2 field-required">
            <el-row :gutter="10">
              <el-col :span="10">Select Default Field</el-col>
              <el-col :span="14">
                <div class="form-group">
                   <el-radio v-model="field.default_value" label="YES">YES</el-radio>
                     <el-radio v-model="field.default_value" label="NO">NO</el-radio>
                     <el-button @click="field.default_value = ''">None Selected</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
            <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
 </el-row>
          <el-row :gutter="30">
        
        <el-col :span="12">
          <div class="mt-2 field-required">
            <el-row :gutter="10">
              <is-field-required :field="field" class="field-required"/>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import FieldFilledBy from "./FieldFilledBy";

export default {
  name: "templates-formComponents-YesOrNo",
  props: ["field"],
  components: {
     "TitleAndDescription":()=>import("./TitleAndDescription.vue"),
     "IsFieldRequired":()=>import("./IsFieldRequired"),
     "FieldFilledBy":()=>import("./FieldFilledBy.vue") 
     },
  data() {
    return {
      validations: []
    };
  }
};
</script>

<style lang="scss">
</style>
